<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in itemNav"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { del, provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import { keys } from "postcss-rtl/lib/affected-props";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      itemNav: [],
      user: { role: null, permissions: null },
    };
  },
  created() {
      this.itemNav = this.$options.propsData.items;
  },
  methods: {
    resolveNavItemComponent,
  },
  setup() {
    provide("openGroups", ref([]));
  },
};
</script>
